import React from "react";
import { Layout, RichText, EventCalendar } from "components";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function ContentfulPage(props) {
	return (
		<Layout>
			{!!props.data.contentfulPage.pageContent && (
				<RichText
					references={
						props.data.contentfulPage.pageContent.references
					}
					raw={props.data.contentfulPage.pageContent.raw}
					page={props.data.contentfulPage}
				/>
			)}
			{!!props.data.contentfulPage.calendar && (
				<EventCalendar
					rawEvents={
						props.data.allCalendar.edges[0].node
							.childrenCalendarEvent
					}
				/>
			)}
		</Layout>
	);
}

export const query = graphql`
	query PageQuery($id: String) {
		allCalendar {
			edges {
				node {
					childrenCalendarEvent {
						id
						summary
						description
						start {
							date
							dateTime
						}
						end {
							date
							dateTime
						}
					}
				}
			}
		}
		contentfulPage(id: { eq: $id }) {
			id
			title
			calendar
			pageContent {
				raw
				references {
					... on ContentfulAsset {
						contentful_id
						title
						gatsbyImageData(
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
					... on ContentfulHero {
						__typename
						contentful_id
						heading
						subHeading
						campSpotLink
						backgroundImage {
							gatsbyImageData(
								layout: FULL_WIDTH
								placeholder: BLURRED
							)
						}
					}
					... on ContentfulMapBlock {
						__typename
						contentful_id
						title
						description {
							raw
						}
						mapImage {
							gatsbyImageData(
								layout: FULL_WIDTH
								placeholder: BLURRED
							)
						}
					}
					... on ContentfulInfoRow {
						__typename
						contentful_id
						title
						description {
							raw
						}
						image {
							gatsbyImageData(
								layout: FULL_WIDTH
								placeholder: BLURRED
							)
							title
						}
					}
					... on ContentfulInfoBlockGroup {
						__typename
						contentful_id
						infoBlocks {
							id
							title
							description {
								raw
							}
							ticketLink
							highlightImage {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
								)
								title
							}
							Enlarged
						}
					}
				}
			}
		}
	}
`;
/*
calendarImages {
				childrenImageSharp {
					gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
				}
			}
			*/
